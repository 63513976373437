<template>
  <v-tooltip :bottom=bottom :top=top :right=right :left=left>
    <template v-slot:activator="{ on }">
      <v-badge
        overlap
        :content="text"
        color="transparent"
        offset-x="40%"
        offset-y="100%"
      >
        <v-btn v-if=to v-on="on" plain icon :to=to>
          <v-icon>${{ icon }}</v-icon>
        </v-btn>
        <v-btn v-else-if=href v-on="on" :href="href" icon>
          <v-icon>${{ icon }}</v-icon>
        </v-btn>
        <v-btn v-else @click=action v-on="on" icon>
          <v-icon>${{ icon }}</v-icon>
        </v-btn>
      </v-badge>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'tooltip-button',
  props: {
    action: {
      type: [Function, undefined],
      required: false,
    },
    bottom: {
      type: Boolean,
      default: false,
    },
    left: {
      type: Boolean,
      default: false,
    },
    href: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
      required: true,
    },
    right: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      required: false,
    },
    tooltip: {
      type: String,
      required: true,
    },
    to: {
      type: Object,
      default: () => {},
      required: false,
    },
    top: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
</style>
